<template>
  <div id="step_ad_plan" class="step-ad-plan-container">
    <a-tabs v-if="+steps === 1" :activeKey="activeKey" @tabClick="tabClick" :animated="false">
      <a-tab-pane :key="1" :tab="`${isAdd ? '添加' : '编辑'}广告计划`" :force-render="true">
        <AdPlanForm ref="AdPlanForm" />
      </a-tab-pane>
      <a-tab-pane v-if="isAdd && steps === 1" :key="2" tab="选择已有计划" :force-render="true">
        <div class="select_plan">
          <span style="font-size: 14px; font-weight: 600; margin-right: 20px">计划名称</span>
          <a-select
            @change="changeSelectPlanId"
            @focus="getAdPlanList"
            style="width: 400px"
            placeholder="请选择已有广告计划"
            v-model="selectPlanId"
            allowClear
            showSearch
            :filter-option="filterOption"
          >
            <a-select-option v-for="item in adplanList" :key="item.id" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </div>
      </a-tab-pane>
    </a-tabs>
    <AdPlanViewVue v-if="isAdd && selectPlanId && activeKey === 2" :adPlanInfo="selectPlanInfo" />
  </div>
</template>

<script>
import AdPlanForm from './AdPlanForm'
import AdPlanViewVue from './AdPlanView.vue'
import { getAdPlanList } from '@/api/advertisementStep'
export default {
  components: { AdPlanForm, AdPlanViewVue },
  inject: ['currentAdPlanId', 'changePlanId', 'currentAdGroupId', 'changeGroupId'],
  data () {
    return {
      // tab值
      activeKey: 1,
      // 当前步骤
      steps: 1,
      // 是否是添加
      isAdd: false,
      // 选中的已创建的广告计划
      selectPlanId: undefined,
      // 广告计划列表
      adplanList: [],
      // 选中的广告计划信息
      selectPlanInfo: {}
    }
  },
  watch: {
    $route: {
      handler (val) {
        // 获取当前步骤
        this.steps = val && val.query && +val.query.steps
        // 刷新页面处理
        // 非第一步，显示详情
        this.steps && this.steps !== 1 && (this.activeKey = 2)
        // 第一步，显示form表单
        this.steps && this.steps === 1 && (this.activeKey = 1)
        // 获取当前是否是添加状态
        this.isAdd = val.name === 'addadsteps'
        // 编辑状态获取计划ID
        if (this.isAdd) {
          !this.selectPlanId && (this.selectPlanId = val && val.query && val.query.adPlanId)
          this.selectPlanId && this.getPlanInfo(this.selectPlanId)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // teb切换事件
    tabClick (e) {
      this.activeKey = e
      // 切换到存在已创建tab，传给父级，改变按钮
      this.$emit('handleIsSelect', e === 2)
    },
    // 调用子组件form表单的提交方法
    handleSubmitAdPlan () {
      return new Promise(async (resolve) => {
        const res = await this.$refs.AdPlanForm.handleSubmitAdPlan()
        if (this.isAdd) {
          res.code === 200 && (this.selectPlanId = res.data.id)
          res.code === 200 && (this.selectPlanInfo = res.data)
          res.code === 200 && (this.activeKey = 2)
        }
        // 抛出给父级组件
        resolve(res)
      })
    },
    // 获取广告计划列表
    async getAdPlanList () {
      const resp = await getAdPlanList()
      this.adplanList = resp && resp.data
    },
    // tab2 改变计划 获取广告计划详情
    changeSelectPlanId (e) {
      this.changePlanId(e)
      if (e) {
        this.selectPlanInfo = this.adplanList.find((item) => +item.id === +this.selectPlanId)
      }
    },
    // 获取广告计划详情
    async getPlanInfo (id) {
      await this.getAdPlanList()
      this.changeSelectPlanId(id)
    }
  }
}
</script>

<style lang="less" scoped>
.step-ad-plan-container {
  .select_plan {
    display: flex;
    height: 40px;
    line-height: 36px;
    margin: 30px 0;
  }
}
</style>
